import React, { useEffect } from 'react';
import { Container, Header, Button, Segment } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';

const FileView = (props) => {
  const { content } = props;

  const downloadUrl = flattenToAppURL(content.file?.download);
  const contentType = content.file?.['content-type'];
  const isPdf = contentType === 'application/pdf';

  const getDisplayUrl = (url) => {
    return url.replace('@@download', '@@display-file');
  };

  const displayUrl = getDisplayUrl(downloadUrl);

  // Auto redirect for PDFs without breaking the back button
  useEffect(() => {
    if (isPdf) {
      // Replace the current URL in the browser history (instead of pushing a new one)
      window.location.replace(displayUrl);
    }
  }, [isPdf, displayUrl]);

  const renderFileContent = () => {
    if (isPdf) {
      return (
        <Button
          as="a"
          href={displayUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          View PDF
        </Button>
      );
    } else {
      return (
        <Button
          as="a"
          href={downloadUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download File
        </Button>
      );
    }
  };

  return (
    <Container id="view-wrapper file-view">
      <h1 className="documentFirstHeading">{content.title}</h1>
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      <Segment>
        <Header as="h2">File Content</Header>
        {renderFileContent()}
      </Segment>
      <Segment>
        <Header as="h3">File Details</Header>
        <p>File Type: {contentType}</p>
        <p>File Size: {content.file?.size} bytes</p>
      </Segment>
    </Container>
  );
};

export default FileView;
